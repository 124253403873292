<template>
  <Card title="我的待办日历" class="tasks" :dis-hover="true" :bordered="false">
    <template #extra>
      <span>仅显示前后各1个月的待办数据</span>
    </template>
    <div class="calendar">
      <div class="left" @click="switchWeek('perv')" :class="{disabled:currWeekIndex===0}">
        <Icon type="ios-arrow-back"/>
      </div>
      <div class="content" ref="dateBox">
        <ul :style="{width:(dateWidth+2)*dateList.length+'px',marginLeft:-dateWidth*7*currWeekIndex+'px'}" class="dateContainer">
          <li class="week" v-for="(item, index) in dateList" :key="index" :style="{width:dateWidth+'px'}">
            <Badge :offset="[14,15]" :count="item.count" class="day">
              <div
                  class="date"
                  :class="{
                  'today': item.y_m_d===formatDate(new Date()),
                  'active': item.y_m_d===currDay,
                  'empty_date': !item.week
                }"
                  @click="itemClick(index)"
              >
                <p class="info">{{ item.week }}</p>
                <p class="dayInfo">{{ item.day }}</p>
                <p class="info">{{ item.y_m }}</p>
              </div>
            </Badge>
          </li>
        </ul>
      </div>
      <div class="right" @click="switchWeek('next')" :class="{disabled:(currWeekIndex+1)*7>=dateList.length}">
        <Icon type="ios-arrow-forward"/>
      </div>
    </div>
    <div>
      <Tabs v-model="tab">
        <TabPane :label="'全部('+allCount+')'" name="1">
          <list-template v-if="tab==='1'" :tableData="allData"></list-template>
        </TabPane>
        <TabPane :label="'检查任务('+checkData.length+')'" name="2">
          <list-template v-if="tab==='2'" :tableData="checkData"></list-template>
        </TabPane>
        <TabPane :label="'整改单('+rectificationsData.length+')'" name="3">
          <list-template v-if="tab==='3'" :tableData="rectificationsData"></list-template>
        </TabPane>
        <TabPane :label="'服务计划('+planData.length+')'" name="4">
          <list-template v-if="tab==='4'" :tableData="planData"></list-template>
        </TabPane>
        <TabPane :label="'报告('+reportData.length+')'" name="5">
          <list-template v-if="tab==='5'" :tableData="reportData"></list-template>
        </TabPane>
      </Tabs>
    </div>
  </Card>
</template>

<script>
import {formatDate, all} from "ys-admin";
import {getCheckList} from '@/api/common'
import {subPlanList} from '@/api/plan'
import {getReportList} from '@/api/report'
import {getAmendList} from '@/api/amend'

export default {
  name: 'task',
  data() {
    return {
      tab: '1',
      currDay: '',
      startAt: '',
      endAt: '',
      dateWidth: '',
      currWeekIndex: 0,
      dateList: [],
      List: [],
    }
  },
  components: {
    'list-template': () => import('./listTemplate'),
  },
  computed: {
    allData() {
      return this.currDay ? this.List.filter(item => item.overDate === this.currDay) : this.List
    },
    checkData() {
      return this.currDay ? this.List.filter(item => item.overDate === this.currDay && item.taskType === 0) : this.List.filter(item => item.taskType === 0)
    },
    rectificationsData() {
      return this.currDay ? this.List.filter(item => item.overDate === this.currDay && item.taskType === 1) : this.List.filter(item => item.taskType === 1)
    },
    planData() {
      return this.currDay ? this.List.filter(item => item.overDate === this.currDay && item.taskType === 2) : this.List.filter(item => item.taskType === 2)
    },
    reportData() {
      return this.currDay ? this.List.filter(item => item.overDate === this.currDay && item.taskType === 3) : this.List.filter(item => item.taskType === 3)
    },
    formatDate() {
      return formatDate;
    },
    allCount() {
      return this.checkData.length + this.rectificationsData.length + this.planData.length + this.reportData.length;
    },
  },
  mounted() {
    const today = new Date();
    // this.currDay = formatDate(today);
    this.startAt = today.setMonth(today.getMonth() - 1);
    this.endAt = today.setMonth(today.getMonth() + 2);
    this.getDataList()
  },
  methods: {
    /*获取待办数据*/
    getDataList() {
      let startAt = formatDate(new Date(this.startAt));
      let endAt = formatDate(new Date(this.endAt));
      all([
        // 任务 /azx/List和/special/List接口传ServeTimeStartAt和ServeTimeEndAt 是serveTime还有finishAt
        getCheckList({statusList: [2, 3, 5], serveTimeStartAt: startAt, serveTimeEndAt: endAt, limit: -1}),
        // 隐患 /comment/List传UpdatedStartAt和UpdatedEndAt   是隐患的updatedAt
        getAmendList({statusList: [1, 3], updatedStartAt: startAt, updatedEndAt: endAt, limit: -1, checkStatusList: [30, 20], isCheckReport: 1}),
        // 服务计划 /servePlan/subPlan/List传FinishStartAt和FinishEndAt    是服务计划的finishAt
        subPlanList({noCheck: 1, finishStartAt: startAt, amendStatus: 3, finishEndAt: endAt, limit: -1}),
        // 报告 /report/List传UpdatedStartAt   是报告的updatedAt
        getReportList({status: 0, updatedStartAt: startAt, updatedEndAt: endAt, limit: -1, checkStatusList: [20, 40], isCheckReport: 1}),
      ]).then(res => {
        // 对应将用于匹配时间的字段创建并根据type值将数据对应分类
        this.List = res.map((item, index) => {
          return (item.data.list || []).map(subItem => {
            subItem.taskType = index;
            switch (index) {
              case 0:
              case 2:
                subItem.overDate = formatDate(new Date(subItem['finishAt']));
                break;
              case 1:
              case 3:
                subItem.overDate = formatDate(new Date(subItem['updatedAt']));
                break;
            }
            return subItem
          })
        }).flat();
        this.getMonthWeek();/*生成待办日历*/
      })
    },
    getMonthWeek() {
      this.dateList = [];
      const len = (this.endAt - this.startAt) / 3600 / 1000 / 24;
      // 所需补全的日期的长度
      let completionNum = 0
      for (let i = 0; i <= len; i++) {
        let date = new Date(this.startAt + 3600 * 1000 * i * 24), count = 0
        let weekStr = "日一二三四五六"
        // 使用overDate字段匹配时间，计算对应日期下的数据条数(直接根据全部数据条数计算即可，对应tab标题会显示自己的数据条数)
        this.List.forEach((item) => {
          if (item.overDate === formatDate(date)) count++;
        })
        // 需加上差值后可计算出滚动到屏的数值
        if (formatDate(new Date()) === formatDate(date)) this.currWeekIndex = Math.floor((i + completionNum) / 7);
        this.dateList.push({
          week: weekStr.charAt(date.getDay()),
          y_m_d: formatDate(date, 'yyyy-MM-dd'),
          y_m: formatDate(date, 'yyyy-MM'),
          day: date.getDate(),
          count
        })
        // 前补全缺少的天数,无需补全真实数据只需渲染空
        if (i === 0 && date.getDay() !== 0) {
          completionNum = date.getDay()
          this.dateList.unshift(...weekStr.slice(0, completionNum).split(''))
        }
      }
      // 计算一下需显示的每组的每个日期的宽度
      this.$nextTick(() => {
        this.dateWidth = Math.floor(this.$refs.dateBox.offsetWidth / 7);
      })
    },
    // 上下切换周,改变第一组的margin-left值即可
    switchWeek(mode) {
      switch (mode) {
        case 'perv':
          if (this.currWeekIndex > 0) {
            this.currWeekIndex -= 1
          }
          break;
        case 'next':
          if ((this.currWeekIndex + 1) * 7 < this.dateList.length - 1) {
            this.currWeekIndex += 1
          }
          break;
      }
    },
    itemClick(index) {
      this.currDay = this.currDay === this.dateList[index].y_m_d ? '' : this.dateList[index].y_m_d || this.currDay;
    },
  },
}
</script>
<style lang="less" scoped>
.task {
  background-color: #fff;
  margin-bottom: 10px;

  .title {
    border-bottom: 1px solid #ececec;
    padding: 5px 7px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.calendar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .content {
    flex-basis: 92%;
    white-space: nowrap;
    overflow: hidden;
    height: 88px;

    .dateContainer {
      display: flex;
      transition: all .5s ease;

      .week {
        list-style: none;
        text-align: center;

        .day {
          text-align: center;

          .date {
            text-align: center;
            padding: 5px 20px;
            border-radius: 3px;

            p {
              height: 25px;
              line-height: 25px;

              &.info {
                font-size: 12px;
                color: #959595;
              }

              &.dayInfo {
                font-size: 18px;
                font-weight: 400;
              }
            }
          }

          .empty_date {
            padding: 5px 43px;
          }
        }
      }
    }
  }

  .left, .right {
    line-height: 88px;
    height: 88px;
    font-size: 30px;
    flex-basis: 4%;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      background-color: #2d8cf0;
      color: #ffffff;
    }

    &.disabled {
      color: #cecece;
      background-color: #ffffff;
    }
  }
}
</style>
